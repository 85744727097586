@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
/* @import url('./fonts/Proxima-Nova/fonts.css'); */

html, html * {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: '-apple-system', 'Proxima Nova', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, 'PT Mono', monospace;
}

.hoverable :hover {
  cursor: pointer;
}

.SnackbarContainer-top.Uikit-SnackbarContainer {
  top: 48px!important;
}
.SnackbarContainer-top.Uikit-SnackbarContainer ~ .SnackbarContainer-top.Uikit-SnackbarContainer { 
  top: inherit;
}